import moment from "moment";
import { required, useMutation, useNotify, useRedirect } from "ra-core";
import { Datagrid, List, TextField, DateField, BooleanField, ReferenceInput, Edit, TextInput, DateInput, BooleanInput, Filter, SelectInput, Create, FunctionField, AutocompleteInput, TabbedForm, FormTab } from "ra-ui-materialui";
import { useCallback, useEffect, useState } from "react";
import { TabsUseStyles } from "../App";
import { WorkingCenterField } from "../components/Fields/WorkingCenterField";
import { getWorkingCenter, getCommunicationDevicesFirmwareModel, getCommunicationDevicesTvboxModel, getComunicationDeviceType } from "../services/master-tables.service";
import { PostBulkActionButtons, CustomToolbarPermissions } from "./utils";

const validateDevice = (values) => {
    const errors = {};
    if (!values.code) {
        errors.code = 'Requerido';
    }
    /* if (values.isFailed && !values.failDetail) {
        errors.failDetail = 'Requerido';
    } */
    if (values.purchaseDate && values.installationDate){
        if (moment(values.purchaseDate) > moment(values.installationDate)) {
            errors.purchaseDate = 'La fecha de compra no puede ser superior a la fecha de instalación'
        }
    }
    return errors
};

const DevicesTitle = ({ record }) => {
    return <span>Dispositivo {record ? `${record.code}` : ''}</span>;
};

const DeviceFilter = props => {
    const [workingCenter, setworkingCenter] = useState()

    useEffect(() => {
        getWorkingCenter().then(
            res => {
                setworkingCenter(res.elements);
            }
        )
    }, []);

    return (
        <Filter {...props}>
            <TextInput label="Código dispositivo" source="code" alwaysOn />
            {/* <DateInput label="Fecha de instalación" source="installationDate" alwaysOn/> */}
            <SelectInput label="Tipo de asignación" source="assignType" alwaysOn choices={[
                { id: 'all', name: 'Todos' },
                { id: 'assigned', name: 'Asignado' },
                { id: 'unassigned', name: 'Sin asignar' },
            ]} />
            {
                workingCenter ?
                    <AutocompleteInput
                        label="Centro de Trabajo"
                        source="associatedWorkingCenterId"
                        choices={workingCenter}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        resettable={true}
                    />
                    : null
            }
            <TextInput label="NIF/NIE Usuario" source="identificationNumber" />
            <BooleanInput label="Fallo dispositivo" source="isFailed" />
        </Filter>
    )
}

export const DeviceList = props => (
    <List bulkActionButtons={props.permissions.devices.delete ? <PostBulkActionButtons /> : false} {...props} exporter={false} filters={<DeviceFilter />} filterDefaultValues={{ assignType: 'all' }}>
        <Datagrid rowClick="edit" isRowSelectable={record => props.permissions.devices.delete}>
            {/* <TextField source="id" /> */}
            <TextField label="Código dispositivo" source="code" sortBy="code" />
            <DateField label="Fecha de instalación" source="installationDate" />
            <DateField label="Fecha de compra" source="purchaseDate" />
            <BooleanField label="Fallo dispositivo" source="isFailed" />
            <TextField label="Descripción fallo" source="failDetail" />
            <WorkingCenterField label="Centro de Trabajo" source="associatedWorkingCenterId" sortable={true} sortBy="WorkingCenter.Name" />
            <FunctionField label="NIF/NIE usuario" sortable={true} sortBy="User.IdentificationNumber" render={record => {
                return record.relatedUser ? record.relatedUser.identificationNumber : null
            }} />
            <FunctionField label="Usuario" sortable={true} sortBy="User.Name" render={record => {
                return record.relatedUser ? record.relatedUser.name + ' ' + record.relatedUser.surnames : null
            }} />
        </Datagrid>
    </List>
);

export const DeviceEdit = props => (
    <Edit title={<DevicesTitle />} mutationMode="pessimistic" {...props} val="true" transform={transformDevices}>
        {DeviceForm(props, 'edit')}
    </Edit>
);

export const DeviceCreate = props => (
    <Create mutationMode="pessimistic" {...props} transform={transformDevices}>
        {DeviceForm(props)}
    </Create>
);

const DeviceForm = (props, mode = 'create') => {
    const [workingCenter, setworkingCenter] = useState();
    const classes = TabsUseStyles();
    const [firmwareList, setFirmwareList] = useState();
    const [tvBoxModelList, setTvBoxModelList] = useState();
    const [comminicationDeviceTypeList, setComminicationDeviceTypeList] = useState()

    const redirect = useRedirect();
    const notify = useNotify()
    const [mutate] = useMutation();

    const getDeviceTypeId = (name) => {
        return comminicationDeviceTypeList?.find(e => e?.value === name)?.id
    }
    const save = useCallback(
        async (values) => {
            try {
                const res = await mutate({
                    type: mode === 'create' ? 'create' : 'update',
                    resource: 'devices',
                    payload: { data: transformDevices(values) },
                }, { returnPromise: true });
                notify(`ra.notification.${mode === 'create' ? 'created' : 'updated_item'}`)
                if (mode === 'create') {
                    redirect('edit', props.basePath, res.data.id, res.data)
                } else {
                    redirect('list', props.basePath)
                }
            } catch (error) {
                if (error.error && error.error.code === 4003) {
                    notify(error.error.message, 'error')
                    return {
                        code: error.error.message
                    };
                }
                notify('ra.notification.http_error', 'warning')
            }
        },
        [mutate, mode, notify, props.basePath, redirect],
    );

    useEffect(() => {
        getWorkingCenter().then(
            res => {
                setworkingCenter(res.elements);
            }
        )     
        getCommunicationDevicesFirmwareModel().then(
            res => {
                setFirmwareList(res.elements);
            }
        )
        getCommunicationDevicesTvboxModel().then(
            res => {
                setTvBoxModelList(res.elements);
            }
        )
        getComunicationDeviceType()?.then(
            res => {
                setComminicationDeviceTypeList(res?.elements);
            }
        )
    }, [])

    return (
        <TabbedForm classes={classes} save={save} validate={validateDevice} toolbar={<CustomToolbarPermissions perm="devices.delete" />}>
            <FormTab label="Dispositivo" contentClassName="tab-container">
                <TextInput label="Código dispositivo" source="code" validate={[required()]} />
                <DateInput label="Fecha de instalación" source="installationDate" />
                <DateInput label="Fecha de compra" source="purchaseDate" />
                {
                    workingCenter ?
                        <AutocompleteInput
                            label="Centro de Trabajo"
                            source="associatedWorkingCenterId"
                            choices={workingCenter}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                        />
                        : null
                }
                <FunctionField label="Usuario" render={record => {
                    return record.relatedUser ?
                        record.relatedUser.identificationNumber + ' - ' + record.relatedUser.name + ' ' + record.relatedUser.surnames
                        : 'Sin asignar'
                }} />
                {           
                    tvBoxModelList ?
                        <AutocompleteInput
                            label="Modelo de TvBox"
                            source="associatedTvBoxModelId"
                            choices={tvBoxModelList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                        />
                        : null
                }
                {           
                    firmwareList ?
                        <AutocompleteInput
                            label="Versión firmware"
                            source="associatedFirmwareModelId"
                            choices={firmwareList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                        />
                        : null
                }
                <ReferenceInput
                    label="Camara Asignada"
                    source="associatedCameraId"
                    reference="cameraDevices"
                    filter={{ "unassigned": true, "assigned": false, "isFailed": false, "communicationDeviceTypeId": getDeviceTypeId("Cámara"), "isAvailable": true }}
                >
                    <AutocompleteInput translateChoice={false} optionText={(record) => {
                        return record.code
                    }} resettable={true} />
                </ReferenceInput>
                <BooleanInput label="Devuelto para revisión" source="returned" />
                <ReferenceInput perPage={1000} label="Contrato" source="associatedContractId" reference="contracts">
                    <AutocompleteInput
                        optionText="contractNumber"
                        resettable={true}
                    />            
                </ReferenceInput>
            </FormTab>
            <FormTab label="Fallo dispositivo" contentClassName="tab-container">
                <BooleanInput label="Fallo dispositivo" source="isFailed" />
                <TextInput label="Descripción fallo" source="failDetail" />
            </FormTab>
        </TabbedForm>
    )
}

const transformDevices = (data) => {
    // Pasamos fechas a formato UTC Z
    const installationDate = data?.installationDate ? moment(data?.installationDate)?.format('yyyy-MM-DDTHH:mm:ss') : null;
    const purchaseDate = data?.purchaseDate ? moment(data?.purchaseDate).format('yyyy-MM-DDTHH:mm:ss') : null;

    return {
        ...data,
        installationDate,
        purchaseDate
    }
}