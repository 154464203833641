import NetworkSpeed from "network-speed";


export class SpeedTest {
    

    
    testDownloadSpeed = async () => {
    const testNetworkSpeed = new NetworkSpeed();

    try {
        const baseUrl = "https://eu.httpbin.org/stream-bytes/10000000"; 
        const fileSizeInBytes = 10000000; // Tamaño del archivo 
    
        const speed = await testNetworkSpeed.checkDownloadSpeed(baseUrl, fileSizeInBytes);
        if (speed && speed.mbps) {
          const downloadSpeedMbps = Math.round(speed.mbps); // Redondeamos la velocidad a un entero
          console.log("Velocidad de descarga (Mbps):", downloadSpeedMbps);
          return downloadSpeedMbps; // Retornamos la velocidad redondeada
        }
        return 0; // Retorno en caso de no obtener la velocidad
      } catch (error) {
        console.error("Error al medir la velocidad de descarga:", error);
        return 0; // Retorno en caso de error
      }
    };

  testUploadSpeed = async () => {
    const testNetworkSpeed = new NetworkSpeed();

    try {
      const options = {
        hostname: "eu.httpbin.org",
        port: 80,
        path: "/post",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      };

      const fileSizeInBytes = 5000000; 
      const speed = await testNetworkSpeed.checkUploadSpeed(options, fileSizeInBytes);
     
      if (speed && speed.mbps) {
        const uploadSpeedMbps = Math.round(speed.mbps); // Redondeamos la velocidad a un entero
        console.log("Velocidad de subida (Mbps):", uploadSpeedMbps);
        return uploadSpeedMbps; // Retornamos la velocidad redondeada
      }
    } catch (error) {
      console.error("Error al medir la velocidad de subida:", error);
      return 0; // Retorno en caso de error

    }
  };


  
}