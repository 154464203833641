import { Layout, AppBar, Button, useRefresh} from 'react-admin';
import { Box, Typography } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import image from './assets/image/Ingesan_NEG.png';
import { useEffect, useState } from "react";

const MyAppBar = (props) => {
    const refresh = useRefresh();
    const [urlName, setUrlName] = useState(null);


    
    useEffect(() => {
        const button = document.querySelector('header button[aria-label="Refrescar"]');

        let url = window.location.href;
        if(url.includes('calendar')){
            button.style.display = 'none'; // Ocultar el botón
            setUrlName('Refrescar Calendar');
        } else if(url.includes('home')) {
            button.style.display = 'none'; // Ocultar el botón
            setUrlName('Refrescar Home');
        } else {
            if(button)button.style.display = ''; // Mostrar el botón
            setUrlName(null);
        }
        
        // eslint-disable-next-line
    }, [window.location.href])



    const handleCustomRefresh = () => {
        refresh();
        window.location.reload(); // Recarga la página completamente
        
    };


    return (
        <AppBar {...props}>
            <img src={image} alt="logo" height={48} style={{ marginRight: 10 }} />

            <Box flex="1">
                <Typography variant="h6" id="react-admin-title"></Typography>
            </Box>

            {
                urlName ?
                <Button
                style={{fontSize: '0.7rem', color: 'white', textTransform: 'none'}}
                onClick={handleCustomRefresh}
                //label={urlName}
                startIcon={<RefreshIcon  style={{color: "white", fontSize: "1.5rem"}}/>}
                />:null
            }
           
        </AppBar>
    );
};

export const MyLayout = (props) => <Layout {...props} appBar={MyAppBar}  />;
