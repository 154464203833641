export class SSEConnection {
    sse;
    cbRegistry= {};

    constructor(room, client, token) {
        this.room = room
        this.client = client
        this.token = token
        this.connect()
        return this
    }

    connect(){
        this.sse = new EventSource(`${process.env.REACT_APP_PUBLIC_API}/api/SSENotifier/suscribe/${this.room}/${this.client}?access_token=${this.token}`);
        this.sse.onerror = () => {
            if (this.sse.readyState === EventSource.CLOSED) {
                setTimeout(() => this.connect(), 3000);
            }

        };
        this.sse.onopen = () => {
            Object.keys(this.cbRegistry).forEach(eventtype => {
                this.sse.addEventListener(eventtype, this.cbRegistry[eventtype])
            })
        };
    }
    
    on(eventtype, cb){
        this.cbRegistry[eventtype] = cb;
        this.sse.addEventListener(eventtype, cb)
        return this
    }
    close() {
        this.sse.close()
    }
}