import {
    RolePermission, UsersPermissions, AdminsPermissions, ProfessionalPermissions, ActivitiesPermissions,
    ScheduledActivitiesPermissions, DevicesPermissions, ComunicationDevicesPermissions, ContactsPermissions,
    UserHistoryPermissions, UserInteractionsPermissions, UserDocumentsPermissions, MasterTablePermissions,
    ContractingAuthoritiesPermissions, ContractsPermissions, SuperAdminsPermissions, InstallationsPermissions,
    HousingPlanDocumentsPermissions, CameraDevicesPermissions, RecordsPermissions
} from "./permisions.model"

export default new RolePermission([
    new UsersPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: true }),
    new AdminsPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: true }),
    new ProfessionalPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: true }),
    new ActivitiesPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: true }),
    new ScheduledActivitiesPermissions({ enabled: true, list: false, create: true, edit: true, show: true, del: false }),
    new DevicesPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: true }),
    new ComunicationDevicesPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: true }),
    new CameraDevicesPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: true }),
    new ContactsPermissions({ enabled: true, list: false, create: true, edit: true, show: false, del: false }),
    new UserHistoryPermissions({ enabled: true, list: false, create: false, edit: true, show: false, del: false }),
    new UserInteractionsPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: true }),
    new UserDocumentsPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: true }),
    new MasterTablePermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: false }),
    new ContractingAuthoritiesPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: true }),
    new ContractsPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: true }),
    new SuperAdminsPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: false }),
    new InstallationsPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: false }),
    new HousingPlanDocumentsPermissions({ enabled: true, list: true, create: true, edit: true, show: false, del: true }),
    new RecordsPermissions({ enabled: true, list: false, create: true, edit: true, show: false, del: true })
])