import ScheduledActivitiesRecordsProvider from "../../provider/scheduledActivitiesRecords.provider";
import { useEffect, useState, useMemo } from "react";
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from "moment";
import { useGetIdentity } from "ra-core";
import { useHistory } from "react-router-dom";
import { RecordsFormDialog } from "../RecordsFormDialog";

const localizer = momentLocalizer(moment);


const lang = {
    es: {
        week: 'Semana',
        work_week: 'Semana de trabajo',
        day: 'Día',
        month: 'Mes',
        previous: '<',
        next: '>',
        today: 'Hoy',
        agenda: 'Programación',
        event: 'Evento',
        time: 'Tiempo',
        date: 'Fecha',
        noEventsInRange: 'No hay eventos en este rango',
        showMore: (total) => `+${total} más`,
    }
}

export const BigCalendarCustom = (props) => {

    const { isProfesional, includeUsers, onsync } = props;
    const [myEventsList, setMyEventsList] = useState();
    const [dateFilter, setDateFilter] = useState(new Date());
    const [viewMode, setViewMode] = useState('month');

    const [open, setOpen] = useState(false)
    const [record, setRecord] = useState(null)

    const [culture] = useState('es');
    const { defaultDate, messages } = useMemo(
        () => ({
            defaultDate: new Date(),
            messages: lang[culture],
        }),
        [culture]
    )

    const { identity, isLoading } = useGetIdentity();
    let history = useHistory();


    useEffect(() => {
        fetchEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFilter, viewMode, props?.sync]);

    if (isLoading && props?.id === undefined) return null

    const CustomAgendaEvent = ({ event }) => {
        const circleStyle = {
            display: "inline-block",
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            marginRight: "8px",
            backgroundColor: event.isRecord ? "#00ab8e" : "#003488", 
        };

        return (
            <span>
                <span style={circleStyle}></span> 
                {event.isRecord ? (
                            <span>Registro - </span>
                        ) : (
                            <span>Actividad - </span>
                        )}
                {event.title} 
            </span>
        );
    };




    const myEventsListMap = myEventsList?.map((element) => {
        return {
            title: element?.activity?.name,
            start: new Date(element?.startDate),
            end: new Date(element?.endDate),
            isRecord: element?.activity?.isRecord,
            activityId: element?.activity?.id
        };
    });

    const handleNavigate = (date, view) => {
        setViewMode(view);
        setDateFilter(new Date(date));
    };

    const onView = (view) => {
        setViewMode(view);
    };

    // Maneja el clic en un evento
    const handleSelectEvent = (event) => {
        if (event.isRecord) {
            setRecord(event)
            setOpen(true)
            // history?.push(`/records/${event.activityId}`);
        } else {
            history?.push(`/activities/${event.activityId}`);
        }
    };

    const fetchEvents = async () => {
        let dateFilterTo = new Date(dateFilter);

        let startDateFrom = '';

        if (viewMode === 'month') {
            // Avanzar al siguiente mes
            dateFilterTo.setMonth(dateFilter.getMonth() + 1);
            startDateFrom = moment(dateFilter).format('yyyy-MM-01T00:00:00');
            dateFilterTo.setDate(0);
        }
        if (viewMode === 'week') {
            const rango = obtenerRangoSemana(dateFilterTo);
            startDateFrom = moment(rango.inicio).format('yyyy-MM-DDT00:00:00');
            dateFilterTo = new Date(rango.fin.setDate(rango.fin.getDate()));
        }
        if (viewMode === 'day') {
            // Avanzar al siguiente dia
            startDateFrom = moment(dateFilter).format('yyyy-MM-DDT00:00:00');
            dateFilterTo.setDate(dateFilter.getDate());
        }
        if (viewMode === 'agenda') {
            // Avanzar al siguiente dia
            startDateFrom = moment(dateFilter).format('yyyy-MM-DDT00:00:00');
            dateFilterTo.setMonth(dateFilter.getMonth() + 1);
            dateFilterTo.setDate(dateFilter.getDate() - 1);
        }

        // Establecer el día a 1 y la hora, minuto y segundo a cero
        dateFilterTo.setHours(0, 0, 0, 0);

        const res = await ScheduledActivitiesRecordsProvider.getList(null, {
            pagination: { page: 1, perPage: 500 },
            sort: { field: 'StartDate', order: 'DESC' },
            filter: {
                clientId: props?.id !== undefined && !isProfesional ? props.id : undefined,
                profesionalId: props?.id === undefined ? identity?.professionalId : (isProfesional ? props.id : undefined),
                StartDateFrom: startDateFrom,
                startDateTo: dateFilterTo,
                includeUsers: includeUsers,
                includeDeleted: false,
                isCalendar: true
            }
        });

        setMyEventsList(res?.data);
    }


    function obtenerRangoSemana(fechaBase) {
        const fechaInicio = new Date(fechaBase);
        const fechaFin = new Date(fechaBase);

        // Obtener el día de la semana (0=domingo, 1=lunes, ..., 6=sábado)
        const diaSemana = fechaInicio.getDay();

        // Ajustar la fecha al lunes de la misma semana
        const diferenciaLunes = (diaSemana === 0 ? -6 : 1) - diaSemana; // Si es domingo (0), restamos 6 días
        fechaInicio.setDate(fechaInicio.getDate() + diferenciaLunes);

        // Ajustar la fecha al domingo de la misma semana
        fechaFin.setDate(fechaInicio.getDate() + 6);

        return {
            inicio: fechaInicio,
            fin: fechaFin
        };
    }

    return (
        <>
        
        <BigCalendar style={{ width: "100%", height: '40rem', overflow: 'auto' }}
            defaultView="week"
            onView={onView}
            culture={culture}
            defaultDate={defaultDate}
            messages={messages}
            localizer={localizer}
            events={myEventsListMap}
            onNavigate={handleNavigate}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={handleSelectEvent}
            min={new Date(1970, 1, 1, 8, 0, 0)}
            eventPropGetter={(event) => {
                let className = event.isRecord === true ? "calendar-register" : "calendar-activity";
                return { className };
            }}
            components={{
                agenda: {
                    event: CustomAgendaEvent
                },
            }}
        />
        <RecordsFormDialog 
            {...props}
            open={open} 
            onClose={() => setOpen(false)} 
            onsaved={() => {
                setOpen(false)
                onsync()
            }} 
            isModal={true}
            mode={'edit'}
            resource="records"
            isRecord={true}
            id={record?.activityId+ ""}
        />
        </>
    )
}